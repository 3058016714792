/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "welcome-to-a-world-of-conversation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#welcome-to-a-world-of-conversation",
    "aria-label": "welcome to a world of conversation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Welcome to a world of conversation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A hearing-impaired phone opens a world of conversation ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "for a person with hearing loss"), ". When a person experiences hearing loss, phone conversations become more difficult. The lack of physical interaction during a phone call compounds the hearing difficulties. One cannot read the other person’s lips or facial expressions to try to understand the conversation. When the physical and visual elements are missing, it becomes even more important to have a clear auditory experience. Fortunately, there are phones for people with hearing loss which amplify sounds to make the person on the other end audible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In an increasingly global world, families often live far apart from each other. Families and friends stay connected through phone conversations and voice mail. Therefore, being able to use phones for the hearing impaired becomes more important and necessary. Staying connected to love ones gives meaning to life and enriches people’s lives, especially older individuals who may have difficulty traveling to social events. Having telephones for the hard of hearing increases people’s happiness and quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "features-of-a-hearing-impaired-phone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#features-of-a-hearing-impaired-phone",
    "aria-label": "features of a hearing impaired phone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Features of a hearing-impaired phone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing impaired phones are commonly known as amplified phones. They have a couple of key features to make speech audible for those with hearing loss. First, amplified phones have a built-in amplifier to increase the intensity of sounds. Secondly, amplified phones offer tone control to adjust the frequency of the caller’s voice. Amplified phones have several channels to personalize your frequency settings. People with hearing loss often lose ", React.createElement(_components.a, {
    href: "/hearing-loss/high-frequency",
    className: "c-md-a"
  }, "the ability to hear high-pitched frequencies"), ". With high frequency hearing loss, it becomes difficult to hear children’s and women’s voices. A hearing-impaired phone increase the frequencies of your granddaughter’s and daughter’s voice so you can fully hear them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing impaired phones come with other helpful features to consider. For instance, a hearing-impaired phone could come with extra-large number buttons to make it easier to see and press the buttons. There are also amplified phones made with speaker phones. Speaker phones are very convenient because you don’t have to hold a receiver. You could sit peacefully on your sofa as you have a hands-free conversation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some phones have outgoing speech amplification. If you speak softly or low, this feature helps the person on the other line hear you better. People who have hearing loss sometimes start speaking softly. Ask for feedback from your friends or family if you think you may be speaking too softly. Some phones offer hearing aid compatibility which is important if you have or are considering wearing ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many phones for hearing impaired are under $100. ", React.createElement(_components.a, {
    href: "/hearing-aids/affordable",
    className: "c-md-a"
  }, "They are an affordable way to increase the quality and clarity of your conversations"), ", and the quality of your life. No longer will your family members be upset that you can’t hear them. You will be happy to once again participate fully in their lives!"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-hearing-impaired-phones",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-hearing-impaired-phones",
    "aria-label": "types of hearing impaired phones permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of hearing impaired phones"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many types of hearing impaired phones or amplified phones. When choosing a hearing-impaired phone, it is important to determine how much amplification is necessary for you to hear well. Some phones will amplify sounds up to 50 decibels above the normal sounds. Others will amplify sounds up to 90 decibels. Those with severe hearing loss might need amplification up to 90 decibels. Hearing impaired phones comes with ringers up to 95 decibels and some even come with visuals ringers. ", React.createElement(_components.a, {
    href: "/audiologists",
    className: "c-md-a"
  }, "A licensed hearing specialist"), " can conduct a thorough ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " to help you determine how much amplification you need."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you determine how much amplification you need, you can choose the design of the phone which best suits you. There are amplified phones with cords, cord phones with expansion headsets, amplified wireless phones, and amplified Bluetooth phones. When deciding between a cord phone and a cordless phone, you must think about your needs. Do you like to walk around a lot during conversations? Do you like to have a charged phone in a stationary place? If you like both, then choose the cord phones with extra handset."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another type of hearing impaired phone is the captioned telephone. This is a hearing-impaired phone which combines amplification with large screens to display the words of the caller. These types of phones for hearing impaired offer people a visual way to engage in conversation. A person listens to clear, amplified sounds while also reading the text."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is important to consider the quality of the technology in the phone. ", React.createElement(_components.a, {
    href: "/hearing-aids/reviews/",
    className: "c-md-a"
  }, "Read reviews"), " about hearing impaired phones to make sure the call clarity is high, the amplification is clear, and that the tone channels are enough to suit your auditory needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cell-phones-for-hearing-impaired",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cell-phones-for-hearing-impaired",
    "aria-label": "cell phones for hearing impaired permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cell phones for hearing impaired"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cell phones for hearing impaired offer extra loud speakers as well as large buttons for easier operation. While there are cell phones for hearing impaired, the options are not as plentiful as landline options. Instead, cell phone companies are increasingly ensuring that cell phones are hearing aid compatible. Hearing aid compatibility means that the cell phone has been tested for the level of audible interference or radio frequency interference. Cell phones are given an M-rating. The higher the M-rating, the lower the radio frequency emissions and the better the signal quality. For good call quality, the M-rating of the cell phone and hearing aid should add up to at least a rating of five."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "Many of the modern hearing aids offered by hear.com"), " are built with telecoils. Telecoils convert magnetic signals from cell phones into sounds. Telecoils do not whistle or create feedback since they only receive sound from the electronic device. ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth hearing aids have t-coils"), " in order to pick up radio and electromagnetic signals from streamers that convert Bluetooth signals from TVs, music players, cell phones, and more."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many mobile phones are now TTY compatible. A TTY or TTD, Telecommunication Device for the Deaf, is used by deaf and hearing impaired people. TTY devices are keyboard devices which attach to landlines and cell phones. They allow users to type messages back and forth. Both users must have to have the TTY device to write back and forth. The written messages are sent through the phone line. If you do not have a TTY device, you could purchase a cell phone with a large screen and keyboard."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "other-assisted-listening-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-assisted-listening-devices",
    "aria-label": "other assisted listening devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other assisted listening devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you do not want to purchase a new landline phone, you could purchase phone accessories to help with hearing. Phone amplifiers for hearing impaired are a great alternative to hearing impaired telephones. These hearing devices work with corded and cordless phones to improve sound quality. They amplify sounds and increase tones just like hearing impaired phones. Some phone amplifiers, called in-line amplifiers, replace the cord between the phone and the receiver. The setting buttons allow you to control volume and tone. Portable phone amplifiers slip over the telephone handset. Usually, portable amplifiers are suitable for people with mild hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "How many times have you missed a phone call? Solve this problem with a telephone ring amplifier. The prices for such devices average $30. They are an inexpensive way to solve the missed call problem. As you can see, there are a variety of hearing amplifiers available to you. A hearing specialist will give you information about the level of your hearing loss to help you decide which devices are best for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ask-the-hearcom-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ask-the-hearcom-experts",
    "aria-label": "ask the hearcom experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ask the hear.com experts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our hearing experts and specialists can also help you decide if you would like to purchase hearing aids. Hearing aids improve your hearing in all situations and help improve your quality of life. hear.com experts will also give you information about hearing aid compatible cell phones. Many of today’s hearing aids have Bluetooth built-in, so you could experience great call quality with your hearing aids connected to your mobile phone. With Bluetooth connection, the hearings aids’ T-coils receive signals directly from the phone without interference! Great call quality is a just a phone call away. Contact us today for more information!"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
